<template>
    <div class="app-container">
      <el-row class="margin-bottom-20">
        <el-col :lg="16" :xs="16" :offset="0">
          <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID Paypal, Nombre Suscriptor, Correo Suscriptor" v-model="searchForm.query"
            v-on:change="handleSearchFilter()" clearable></el-input>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
            icon="el-icon-search">Buscar</el-button>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
            icon="el-icon-delete">Limpiar</el-button>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button
            @click.stop.prevent="handleExport"
            type="success"
            icon="el-icon-bottom"
            size="mini"
          >Exportar</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="grid-content" style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="24">
          <el-select v-model="searchForm.status" v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
            <el-option
              v-for="option in statusOptions"
              :key="option.status"
              :label="option.status"
              :value="option.status">
            </el-option>
          </el-select>
        </el-col>
        <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="14" :xs="24" justify="end">
          <div class="grid-content bg-purple">
            <span class=" margin-filter">Registros por fecha</span>
            <el-date-picker
              v-model="filterDateRegisterRange"
              v-on:change="handleFilterDateRegister()"
              size="mini"
              type="daterange"
              align="right"
              unlink-panels
              format="dd-MM-yyyy"
              range-separator="a"
              start-placeholder="Fecha inicial"
              end-placeholder="Fecha final"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <!---- Paginacion -->
      <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-row>
      <!---- Tabla de Donaciones ----->
      <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
        highlight-current-row style="width: 100%">
        <el-table-column label="ID Paypal Suscripción" fixed width="120" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.subscriptionId) }}</div>
              <span>{{ scope.row.subscriptionId !== undefined ? scope.row.subscriptionId.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="ID" width="120" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row._id) }}</div>
              <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Nombre de Suscriptor" align="center" width="220">
          <template slot-scope="scope">
            <span>{{ isNotEmpty(scope.row.userName) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Correo de Suscriptor" align="center" width="220">
          <template slot-scope="scope">
            <span>{{ isNotEmpty(scope.row.userEmail) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Estatus" align="center" width="160">
          <template slot-scope="scope">
            <el-tag  v-if="scope.row.status.toLowerCase() == 'active'"  size="small" type="success">{{ validateStatus(scope.row.status) }}</el-tag>
            <el-tag  v-else-if="scope.row.status.toLowerCase() == 'canceled'"  size="small" type="danger">{{ validateStatus(scope.row.status) }}</el-tag>
            <el-tag  v-else size="small" type="info">{{ validateStatus(scope.row.status) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Monto de Suscripción" align="center" width="160">
          <template slot-scope="scope">
            <span>{{ '$'+scope.row.amount+' '+scope.row.currency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Recurrencia de Suscripción" align="center" width="110">
          <template slot-scope="scope">
            <span>{{ validateInterval(scope.row.interval.toLowerCase()) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Nombre Proyecto" align="center" min-width="160">
          <template slot-scope="scope">
            <span>{{ isNotEmpty(scope.row.projectName) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Clave Proyecto" align="center" width="130">
          <template slot-scope="scope">
            <span>{{ isNotEmpty(scope.row.projectKey) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="ID Producto Paypal" align="center" width="130">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.productId) }}</div>
              <span>{{ scope.row.productId !== undefined ? scope.row.productId.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Fecha último pago" align="center" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.lastPaymentDate | formatDateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operaciones" align="center" width="100">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleGoAndSearchContributions(scope.row.subscriptionId )" size="mini" >Pagos</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Fecha de Creación" width="180" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | formatDateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Opciones" fixed="right" width="120" align="center">
          <template slot-scope="scope">
            <el-button :disabled="checkSubsStatus(scope.row.status)" size="mini" :type="checkSubsColor(scope.row.status)" plain @click="handleCancelSubscription(scope.row._id)">Cancelar</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Fecha actualización" width="180" align="center" fixed="right">
          <template slot-scope="scope">
            <span >{{ scope.row.updated_at | formatDateTime }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
import { searchSubscription, getPageSubscription, exportExcelSubscriptions, filterDataSubscriptions, cancelPaypalSubscription } from '@/api/paypal.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'subscription-strip',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: '',
        userId: null,
        status: null,
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      list: [],
      statusOptions: [],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    var idTemp = this.$route.params._id
    if (idTemp) {
      this.searchForm.userId = idTemp
    }
    this.fetchFilterStatus()
    this.handleSearchFilter()
  },
  methods: {
    checkSubsStatus (statusRow) {
      const status = statusRow.toLowerCase()
      return status !== 'active'
    },
    checkSubsColor (statusRow) {
      const status = statusRow.toLowerCase()
      return status === 'active' ? 'danger' : undefined
    },
    async handleTryCancelSubscription (id) {
      try {
        const response = await cancelPaypalSubscription(id)
        console.log(response)
        if (response.success) {
          this.$message({
            type: 'info',
            message: 'la suscripción fue cancelada correctamente'
          })
        }
        console.log(response)
      } catch (error) {
        const data = error.response.data
        console.log('responseCatch -> data', data)
        if (data.messages) {
          data.messages.forEach((element) => {
            this.$message.error(element)
          })
        }
      }
    },
    handleCancelSubscription (subscriptionId) {
      console.log(subscriptionId)
      this.$confirm('Esta seguro que desea cancelar esta suscripción?', '', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.handleTryCancelSubscription(subscriptionId)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'La suscripción no fué cancelada'
        })
      })
    },
    isNotEmpty (contain) {
      return contain !== undefined || contain !== '' ? contain : 'N/D'
    },
    handleGoAndSearchContributions (id) {
      this.$router.push({ name: 'paypal.contributions', params: { subscriptionId: id } })
    },
    validateInterval (contain) {
      switch (contain) {
        case 'day':
          return 'Diaria'
        case 'week':
          return 'Semanal'
        case 'month':
          return 'Mensual'
        case 'year':
          return 'Anual'
        default:
          return contain
      }
    },
    validateStatus (contain) {
      switch (contain) {
        case 'active':
          return 'Activa'
        case 'approval_pending':
          return 'Pendiente'
        case 'incomplete':
          return 'Incompleta'
        case 'cancelled':
          return 'Cancelada'
        default:
          return contain
      }
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await searchSubscription(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(item => (item.metadata = [item.metadata]))
          console.log(this.list)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPageSubscription(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPageSubscription(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleExport () {
      this.loading = true
      await exportExcelSubscriptions(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-Suscripciones-Paypal-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        userId: null,
        status: null,
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    async fetchFilterStatus () {
      const formData = new FormData()
      formData.append('fieldSearch', 'status')
      filterDataSubscriptions(formData)
        .then((response) => {
          this.statusOptions = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="css">

</style>
